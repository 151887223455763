import { NextPage } from 'next';

import React from 'react';

import { Result } from 'antd';

import BasicLayout from '@components/web/Layout/BaisicLayout';

const NotFoundPage: NextPage = () => {
  return (
    <BasicLayout
      headerOptions={{
        types: [
          {
            type: 'primary',
            response: {
              rightButtons: [{ type: 'appDownload' }, { type: 'category' }],
            },
          },
        ],
      }}
    >
      <Result
        status="404"
        title="404 페이지를 찾을 수 없습니다 :("
        subTitle="입력하신 주소가 정확한지 다시 한 번 확인해주세요"
      />
    </BasicLayout>
  );
};

export default NotFoundPage;
